//This file map all the mcq questions
import {
  Box,
  Fade,
  FormControlLabel,
  RadioGroup,
  Switch,
  Typography,
} from "@mui/material";
import React, { useContext, useState } from "react";
import WarningIcon from "@mui/icons-material/Warning";
import CheckIcon from "@mui/icons-material/Check";
import { AnsContext } from "../Context/AnsState";

const Question = ({
  question,
  qNum,
  questionDescription,
  options,
  activeStep,
  setActiveStep,
  isLastStep,
  checkNextQues,
  questionId,
  questionData,
  currQuesIdx,
}) => {
  const [checked, setChecked] = useState(false);
  const [selected, setSelected] = useState();
  const [error, setError] = useState();
  const { ans, update } = useContext(AnsContext);

  // Scrolls 100vh down
  const handleScroll = () => {
    window.scrollBy({ bottom: 0, top: window.innerHeight, behavior: "smooth" });
  };
  console.log(selected);
  // Scrolls 100vh up
  const handleScrollUp = () => {
    window.scrollBy({
      bottom: 0,
      top: -window.innerHeight,
      behavior: "smooth",
    });
  };

  // goes to previous question
  const handlePrev = () => {
    console.log("hii");
    handleScrollUp();
    let currStep = qNum - 2;
    setTimeout(() => {
      if (activeStep > 0) setActiveStep(currStep);
    }, 600);
    if (activeStep < 0) setActiveStep(0);
  };

  // passes the selected option and question id to the answer object
  const handleSelect = (i) => {
    if (ans[questionId] === undefined) {
      update(i + 1, questionId);
      checkNextQues(questionId, i + 1);
    } else {
      let prevAns = { ...ans };
      if (prevAns !== ans) {
        questionData.splice(currQuesIdx + 1);
        update(i + 1, questionId);
        let flag = false;
        for (var key in ans) {
          if (flag){
            delete ans[key];
          }
          if (questionId == key) flag = true;
        }
        checkNextQues(questionId, i + 1);
      }
    }
    setChecked(true);
    setError("");
    setSelected(i);
    if (!isLastStep) {
      // handleScroll();
      setTimeout(() => {
        setActiveStep(qNum);
      }, 600);
    }
    if (isLastStep == 1) {
      setTimeout(() => {
        handleScroll();
      }, 600);
    }
  };

  // checks if an option is selected or not if yes moves to next question and update the activeStep, if not shows error
  const handleOK = () => {
    if (selected !== undefined) {
      handleScroll();
      setActiveStep(qNum);
    } else {
      setError("Please Select an Option");
    }
  };
  return (
    <>
      <div className="Form-quest">
        <div className="form-main">
          <div className="form-q-num">
            <span className="q-num">{qNum}</span>
            <svg height="10" width="11">
              <path d="M7.586 5L4.293 1.707 5.707.293 10.414 5 5.707 9.707 4.293 8.293z"></path>
              <path d="M8 4v2H0V4z"></path>
            </svg>
          </div>
          <div>
            <div className="quest">
              {question}
              <span className="required">*</span>
            </div>
            {questionDescription && (
              <div className="sub-quest">{questionDescription}</div>
            )}
            <RadioGroup className="options" sx={{ marginTop: "1rem" }}>
              {options &&
                Object.values(options).map((option, i) => (
                  <Box
                    key={i}
                    className={`option ${selected === i && "blinking"}`}
                    onClick={() => {
                      handleSelect(i);
                    }}
                  >
                    <span className="option-num">
                      {String.fromCharCode(97 + i).toUpperCase()}
                    </span>
                    <FormControlLabel
                      value=""
                      control={
                        <Switch
                          sx={{ display: "none" }}
                          checked={checked}
                          onChange={() => {}}
                        />
                      }
                      label={
                        <Typography marginLeft={1} fontSize={18}>
                          {option}
                        </Typography>
                      }
                    />
                    <Fade
                      sx={{ marginLeft: "auto" }}
                      in={selected === i && checked}
                    >
                      {<CheckIcon />}
                    </Fade>
                  </Box>
                ))}
            </RadioGroup>
            {error && (
              <div className="error">
                <WarningIcon
                  sx={{ width: "15px", marginRight: "4px", height: "15px" }}
                />
                {error}
              </div>
            )}
            <div>
              <div>
                <button
                  onClick={() => handlePrev()}
                  className={`hide ${activeStep === qNum - 1 && "prev"}`}
                >
                  Prev
                  <span
                    style={{ color: "white", marginLeft: "7px", fill: "white" }}
                  >
                    <svg height="13" width="16">
                      <path d="M14.293.293l1.414 1.414L5 12.414.293 7.707l1.414-1.414L5 9.586z"></path>
                    </svg>
                  </span>
                </button>
                <button
                  onClick={handleOK}
                  className={`btn-ok ${activeStep === qNum - 1 && "active"}`}
                >
                  OK
                  <span
                    style={{
                      color: "white",
                      marginLeft: "7px",
                      fill: "white",
                    }}
                  >
                    <svg height="13" width="16">
                      <path d="M14.293.293l1.414 1.414L5 12.414.293 7.707l1.414-1.414L5 9.586z"></path>
                    </svg>
                  </span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Question;
