//This file map all the text questions
import React, { useState, useContext } from "react";
import TextField from "@mui/material/TextField";
import { AnsContext } from "../Context/AnsState";

const QuesTextField = ({
  id,
  question,
  qNum,
  placeholder,
  questionDescription,
  handleSubmit,
  type,
  activeStep,
  setActiveStep,
  isLastStep,
}) => {
  const [text, setText] = useState("");
  const [error, setError] = useState();
  const { ans, update } = useContext(AnsContext);

  // Scrolls 100vh down
  const handleScroll = () => {
    window.scrollBy({ bottom: 0, top: window.innerHeight, behavior: "smooth" });
  };
  // Scrolls 100vh up
  const handleScrollUp = () => {
    window.scrollBy({
      bottom: 0,
      top: -window.innerHeight,
      behavior: "smooth",
    });
  };

  // Goes to previous question
  const handlePrev = () => {
    handleScrollUp();
    let currStep = qNum - 2;
    setTimeout(() => {
      if (activeStep > 0) setActiveStep(currStep);
    }, 600);
    if (activeStep < 0) setActiveStep(0);
  };

  // checks if the user has enter text or not, if not it shows error, if yes moves to next question
  const handleSelect = (type) => {
    if (text === "") {
      setError(`${type} cannot be blank`);
    } else if (type === "email") {
      let re =
        /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      if (re.test(text)) {
        moveToNext();
      } else {
        setError("Please enter a valid email address");
      }
    } else {
      moveToNext();
    }
  };

  // checks if the user has enter text or not, if not it shows error, if yes submits the form
  const handleSelectForLast = (type) => {
    if (text === "") {
      setError(`${type} cannot be blank`);
    } else if (type === "email") {
      let re =
        /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      if (re.test(text)) {
        let key = Object.keys(ans)[Object.keys(ans).length - 1];
        console.log(key)
        update(text, key + 1);
        handleSubmit();
      } else {
        setError("Please enter a valid email address");
      }
    }
    else if(type === "number") { 
      console.log(text.length)
      let re =
      /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;
      if (re.test(text) && text.length<11) {
        let key = Object.keys(ans)[Object.keys(ans).length - 1];
        console.log(key)
        update(text, key + 1);
        handleSubmit();
      } else {
        setError("Please enter a valid contact number");
      }
    }else {
      let key = Object.keys(ans)[Object.keys(ans).length - 1];
      update(text, key + 1);
      handleSubmit();
    }
  };

  // Goes to next question and sets the active step respectively
  const moveToNext = () => {
    update(text, qNum);
    handleScroll();
    setTimeout(() => {
      if (activeStep < 2) setActiveStep(qNum);
    }, 600);
  };

  return (
    <>
      <div className="Form-quest">
        <div className="form-main">
          <div className="form-q-num">
            <span className="q-num">{qNum}</span>
            <svg height="10" width="11">
              <path d="M7.586 5L4.293 1.707 5.707.293 10.414 5 5.707 9.707 4.293 8.293z"></path>
              <path d="M8 4v2H0V4z"></path>
            </svg>
          </div>
          <div>
            <span className="quest">
              {question}
              <span className="required">*</span>
            </span>
            {questionDescription && (
              <div className="sub-quest">{questionDescription}</div>
            )}
            <TextField
              fullWidth
              error={error}
              required
              id="standard-basic"
              placeholder={placeholder}
              type={type}
              label={error}
              variant="standard"
              onChange={(e) => {
                setText(e.target.value);
                setError("");
              }}
              value={text}
            />
            {isLastStep == 1 ? (
              <div>
                <button
                  id={id}
                  onClick={() => handlePrev()}
                  className={`hide ${activeStep === qNum - 1 && "prev"}`}
                >
                  Prev
                  <span
                    style={{ color: "white", marginLeft: "7px", fill: "white" }}
                  >
                    <svg height="13" width="16">
                      <path d="M14.293.293l1.414 1.414L5 12.414.293 7.707l1.414-1.414L5 9.586z"></path>
                    </svg>
                  </span>
                </button>
                <button
                  id={id}
                  style={{ width: "82px" }}
                  onClick={() => handleSelectForLast(type)}
                  className={`btn-ok`}
                >
                  Submit
                  <span
                    style={{ color: "white", marginLeft: "7px", fill: "white" }}
                  ></span>
                </button>
              </div>
            ) : (
              <div>
                <button
                  id={id}
                  onClick={() => handlePrev()}
                  className={`hide ${activeStep === qNum - 1 && "prev"}`}
                >
                  Prev
                  <span
                    style={{ color: "white", marginLeft: "7px", fill: "white" }}
                  >
                    <svg height="13" width="16">
                      <path d="M14.293.293l1.414 1.414L5 12.414.293 7.707l1.414-1.414L5 9.586z"></path>
                    </svg>
                  </span>
                </button>
                <button
                  id={id}
                  onClick={() => handleSelect(type)}
                  className={`btn-ok ${activeStep === qNum - 1 && "active"}`}
                >
                  OK
                  <span
                    style={{ color: "white", marginLeft: "7px", fill: "white" }}
                  >
                    <svg height="13" width="16">
                      <path d="M14.293.293l1.414 1.414L5 12.414.293 7.707l1.414-1.414L5 9.586z"></path>
                    </svg>
                  </span>
                </button>
                <span className="btn-desc">
                  press <strong>Enter ↵</strong>
                </span>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default QuesTextField;
