import React, { useState, useEffect, useContext } from "react";
import LinearProgress from "@mui/material/LinearProgress";
import { AnimatePresence, motion } from "framer-motion";
import "./Form.css";
import KeyboardArrowDown from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { formDataStart, formDataEnd } from "../Data/formData";
import Question from "./Question";
import Stepper from "@mui/material/Stepper";
import QuesTextField from "./QuesTextField";
import Step from "@mui/material/Step";
import { disableBodyScroll, enableBodyScroll } from "body-scroll-lock";
import axios from "axios";
import { AnsContext } from "../Context/AnsState";
import { useNavigate, useParams } from "react-router-dom";
import { decode as atob, encode as btoa } from "base-64";
import Swal from "sweetalert2";
import backButton from 'browser-back-button';
import logo from '../images/accredian-logo.png'

//main component of the form
const Form = () => {
  const { id } = useParams();
  let navigate = useNavigate();
  let qNum = 0;
  const [activeStep, setActiveStep] = useState(0);
  const [progress, setProgress] = useState(0);
  const [textQues, setTextQues] = useState();
  const [textQuesEnd, setTextQuesEnd] = useState();
  const [data, setData] = useState();
  const [programCode, setProgramCode] = useState();
  const [firststep, setFirstStep] = useState(1);
  const { ans } = useContext(AnsContext);

  // Scrolls the page to 100vh down
  const handleScroll = () => {
    window.scrollBy({ bottom: 0, top: window.innerHeight, behavior: "smooth" });
  };
  
  
//   window.onscroll = function(ev) {
//       //this function enable or disable the next button of the basis of page position.
//       if ((window.innerHeight + window.scrollY) >= document.body.offsetHeight) {
//           // you're at the bottom of the page
//           document.querySelector(".btn-next").classList.add('disabled');
//       }
//       else{
//         document.querySelector(".btn-next").classList.remove('disabled');
//       }
//     //this function enable or disable the prev button of the basis of page position.
//     if (window.scrollY == 0) {
//       // you're at the top of the page
//       document.querySelector(".btn-prev").classList.add('disabled');
//     }
//     else{
//       document.querySelector(".btn-prev").classList.remove('disabled');
//     }

// };

  //this function for going to previous question  
  const handlePrev = () => {

    if (document.querySelector(".prev")) {
      setFirstStep(0);
      document.querySelector(".prev").click();
    }
  };

  //this function is used for going to next question
  const handleNext = () => {
    if (document.querySelector(".active")) {
      document.querySelector(".active").click();
    }
  };

  // sets the progress of the form
  const updateScroll = () => {
    const currentHeight = window.scrollY;
    const scrollHeight = document.body.scrollHeight - window.innerHeight;
    const currProgress = (currentHeight / scrollHeight) * 100;
    setProgress(currProgress);
  };

  //it's npm package to check before going to the previous page
  backButton.on(()=>{
    if(Object.keys(ans).length>0){
      Swal.fire({
        title: 'Your Progress will be lost',
        showCancelButton: true,
        confirmButtonText: 'Yes I Confirm',
        denyButtonText: `Cancel`,
      }).then((result) => {
        if (result.isConfirmed) {
          navigate(`/form/${btoa(atob(id))}`)
        } else if (result.isDenied) {
        }
      })}
      else{
        navigate(`/form/${btoa(atob(id))}`)
      }
  });

  // finds the next question ans if it does not exists the finds the redirect link
  const checkNextQues = async (quesId, optionSelected) => {
    const rescall = await axios({
      method: "post",
      url: "https://www.accredian.com/insaid_form_backend/data.php",
      data: {
        type: "fetchchild",
        formid: atob(id),
        quesid: quesId,
        choiceid: optionSelected,
      },
    }).then((res) => {
      if (res.data.question_heading != null) {
        setData([...data, res.data]);
        setTimeout(() => {
          handleScroll();
        }, 600);
      } else {
        fetchProgramCode(quesId, optionSelected);
      }
    });
  };

  // redirects the form after submitting to respective course
  const fetchProgramCode = async (quesId, optionSelected) => {
    const rescall = await axios({
      method: "post",
      url: "https://www.accredian.com/insaid_form_backend/data.php",
      data: {
        type: "get_redirection_program",
        qid: quesId,
        option_selected: optionSelected,
      },
    }).then((res) => {
      console.log(res.data);
      setProgramCode(res.data.program_code);
    });
  };

  //this functions fetch the first question of the perticular form 
  const fetchData = async () => {
    const rescall = await axios({
      method: "post",
      url: "https://www.accredian.com/insaid_form_backend/data.php",
      data: {
        type: "fecthdata",
        formid: atob(id),
      },
    }).then((res) => {
      setData(res.data.Questions);
    });
  };

  // Submits the form
  const handleSubmit = () => {
    console.log(ans)
    let name = ans[1];
    let emailkey = Object.keys(ans)[Object.keys(ans).length - 2];
    let email = ans[4];
    let phoneKey = Object.keys(ans)[Object.keys(ans).length - 1];
    let phone= ans[phoneKey];
    delete ans[1];
    delete ans[4];
    // delete ans[emailkey];
    delete ans[phoneKey];
    // let l=Object.keys(ans)[Object.keys(ans).length];
    console.log(ans);
    let ansString = JSON.stringify(ans);
    axios({
      method: "post",
      url: "https://www.accredian.com/insaid_form_backend/data.php",

      data: {
        type: "datasubmit",
        name: name,
        email: email,
        phone: phone,
        answers: ansString,
        formid: atob(id),
        programcode: programCode
      },
    })
      .then(function () {
        if (programCode) {
          navigate(`/form/${btoa(atob(id))}/resultpage/${btoa(programCode)}`);
        } else {
          Swal.fire({
            title: "Success",
            text: "Your form has submitted successfully",
            icon: "success",
            confirmButtonText: "OK",
          }).then(() => {
            window.location.replace("https://www.accredian.com");
          });
        }
      })
      .catch(function () {
        Swal.fire({
          title: "Warning",
          text: "Something went wrong. Please try again!",
          icon: "success",
          confirmButtonText: "OK",
        });
      });
  };

  // handles the enter key to press the next button
  const keyDownHandler = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
      handleNext();
    }
    if (event.keyCode == 40 || event.keyCode == 38 || event.keyCode == 32) {
      event.preventDefault();
    }
  };

  //this hook runs first once page is loadede/mounted 
  useEffect(() => {
    //it's calling fetch data
    fetchData();
    let QuestionsStart = Object.values(formDataStart[0].Questions);
    let textStart = QuestionsStart.filter((ques) => {
      return ques.category == "text";
      
    });

    //this function used to map text question 
    setTextQues(textStart);
    console.log(formDataEnd);
    let QuestionsEnd = Object.values(formDataEnd[0].Questions);
    console.log(QuestionsEnd);
    // QuestionsEnd=Object.values(formDataEnd[1].Questions);
    let textEnd = QuestionsEnd.filter((ques) => {
      return ques.category == "text";
    });

    //this function used to map text question 
    setTextQuesEnd(textEnd);
    window.addEventListener("scroll", updateScroll);
    document.addEventListener("keydown", keyDownHandler);
    //this function used to disable manual scrolling by mouse
    disableBodyScroll(document);
    
    window.onscroll = function(ev) {
      // console.log('called');
      //this function enable or disable the prev button of the basis of page position.
      if (window.scrollY == 0) {
        // you're at the top of the page
        document.querySelector(".btn-prev").classList.add('disabled');
      }
      else{
        document.querySelector(".btn-prev").classList.remove('disabled');
      }

      setTimeout(function(){
        // if (window.scrollY !=0) {
      //this function enable or disable the next button of the basis of page position.
      if ((window.innerHeight + window.scrollY) >= document.body.offsetHeight) {
          // you're at the bottom of the page
          document.querySelector(".btn-next").classList.add('disabled');
      }
      else{
        document.querySelector(".btn-next").classList.remove('disabled');
      }
    // }
      }, 600);
      

    };
  }, []);


  const windowHeight = window.innerHeight;

//this is UI section of the form
  return (
    <motion.div
    initial={{marginTop:"100%"}}
    animate={{marginTop:0}}
    transition={{duration: 0.7}}
    >
      
      <div className="logo">
        <a href="https://accredian.com" target="_blank">
        <img
          src={logo}
          style={{width:"250px",height:"auto"}}
          alt=""
        />
        </a>
        
      </div>
      <div className="progressBar">
        <LinearProgress variant="determinate" value={progress} />
      </div>
      <div className="Form">
        <Stepper activeStep={activeStep} orientation="vertical">
          {textQues &&
            textQues.map((que, i) => (
              <Step>
                <QuesTextField
                  id={i}
                  qNum={++qNum}
                  question={que.question}
                  placeholder={que.placeholder}
                  questionDescription={que.questionDescription}
                  type={que.type}
                  activeStep={activeStep}
                  setActiveStep={setActiveStep}
                  handleSubmit={handleSubmit}
                />
              </Step>
            ))}
          {data &&
            data.map((que, i) => (
              <>
                <Step>
                  <Question
                    key={que.question_id}
                    currQuesIdx={i}
                    question={que.question_heading}
                    qNum={++qNum}
                    isLastStep={que.is_last}
                    questionDescription={que.question_text}
                    options={que.options}
                    activeStep={activeStep}
                    setActiveStep={setActiveStep}
                    checkNextQues={checkNextQues}
                    questionId={que.question_id}
                    questionData={data}
                  ></Question>
                </Step>
              </>
            ))}
          {textQuesEnd &&
            textQuesEnd.map((que, i) => (
              <Step>
                <QuesTextField
                  id={i}
                  qNum={++qNum}
                  isLastStep={que.isLastStep}
                  question={que.question}
                  placeholder={que.placeholder}
                  questionDescription={que.questionDescription}
                  type={que.type}
                  activeStep={activeStep}
                  setActiveStep={setActiveStep}
                  handleSubmit={handleSubmit}
                />
              </Step>
            ))}
        </Stepper>
      </div>
      <button onClick={handlePrev} className="btn-prev">
        <KeyboardArrowUpIcon />
      </button>
      <button onClick={handleNext} className="btn-next">
        <KeyboardArrowDown />
      </button>

      <div className="footer">© 2023 Accredian. All Rights Reserved.</div>
    </motion.div>
  );
};

export default Form;
