import './App.css';
import Form from './Components/Form';
import ExploreForm from './Components/ExploreForm';
import {
  Routes,
  Route,
  useLocation,
  Navigate
} from "react-router-dom";
import { AnimatePresence } from "framer-motion";
import CoursePage from './Components/CoursePage';

function App() {
  const location = useLocation();
  
  return (
    <AnimatePresence>    
      <Routes key={location.pathname} location={location}>
          <Route
            path="/"
            element={<Navigate to="/form/NQ==" replace />}
          />
          <Route path="/form/:id" element={<ExploreForm />} />
          <Route path="/form/:id/form/aW5zins=P2VtYT1hc3ZoeWZu" exact element={<Form />} />
          <Route path="/form/:id/resultpage/:program" exact element={<CoursePage />} />
      </Routes>
    
    </AnimatePresence>
  );
}

export default App;
