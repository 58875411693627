import React, { useEffect, useState } from "react";
import "./ExploreForm.css";
import { Link, useParams } from "react-router-dom";
import { motion } from "framer-motion";
import { decode as atob, encode as btoa } from "base-64";
import axios from "axios";
import logo from '../images/accredian-logo.png'

const ExploreForm = () => {
  const [data, setData] = useState();
  const { id } = useParams();

  const fetchData = async () => {
    const rescall = await axios({
      method: "post",
      url: "https://www.accredian.com/insaid_form_backend/data.php",
      data: {
        type: "frontpageinfo",
        formid: atob(id),
      },
    }).then((res) => {
      setData(res.data);
    });
  };

  const keyDownHandler = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
      document.querySelector(".btn-st").click();
    }
  };
  useEffect(() => {
    fetchData();
    document.addEventListener("keydown", keyDownHandler);
  }, []);
  return (
    <motion.div
      className="Explore-Form"
      initial={{opacity:0}}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.5 }}
    >
      <div className="logo">
        <a href="https://accredian.com" target="_blank">
        <img
          src={logo}
          style={{width:"250px",height:"auto"}}
          alt=""
        />
        </a>
       
      </div>
      {data && (
        <>
          <img id="img-vectorquiz" src={data.image_url} alt="" />
          <h2 className="head">{data.quiz_heading}</h2>
          <span className="subhead">{data.quiz_description}</span>
          <div className="btn-st-parent">
            <Link to={`/form/${id}/form/aW5zins=P2VtYT1hc3ZoeWZu`}>
              <button className="btn-st">Start quiz</button>
            </Link>
            <span className="btn-desc">
              press <strong>Enter ↵</strong>
            </span>
          </div>
        </>
      )}
    </motion.div>
  );
};

export default ExploreForm;
