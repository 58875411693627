import axios from "axios";
import React, { useEffect, useState } from "react";
import "./CoursePage.css";
import { useParams } from "react-router-dom";
import { decode as atob, encode as btoa } from "base-64";
import { motion } from "framer-motion";
import { useNavigate } from "react-router-dom";
import backButton from 'browser-back-button';

const CoursePage = () => {
  let navigate = useNavigate();
  const { program, id } = useParams();
  const [data, setData] = useState();
  const initialSeconds = 15;
  const [seconds, setSeconds ] =  useState(initialSeconds);

  backButton.on(()=>{
    navigate(`/form/${id}/form/aW5zins=P2VtYT1hc3ZoeWZu`)
  });

  const fetchData = async () => {
    const rescall = await axios({
      method: "post",
      url: "https://www.accredian.com/insaid_form_backend/data.php",
      data: {
        type: "fetch_result_page_info",
        program_code: atob(program),
      },
    }).then((res) => {
      setData(res.data);
      // setInterval(data);
    });
  };

  // handles the enter key to click the button 
  const keyDownHandler = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
      document.querySelector(".btn-explore").click();
    }
  };

  useEffect(() => {
    fetchData();
    document.addEventListener("keydown", keyDownHandler);
  //   const Interval = setInterval(() => {
  //     console.log(seconds)
  //     if (seconds > 0) {
  //         setSeconds(seconds - 1);
  //     }
  //     else if (seconds === 0) {
  //       // clearInterval(Interval);
  //       window.location.replace('https://insaid.co')
  //     } 
  // }, 1000)
  // return ()=> {
  //   clearInterval(Interval);
  // };
  const interval = setInterval((data) => {
    console.log(seconds)
    if (seconds > 0) {
          setSeconds(seconds => seconds - 1);
      }
      else if (seconds === 0) {
        clearInterval(interval);
        window.location.replace(data.course_page_url)
      } 
  }, 1000,data);
  return () => { clearInterval(interval); };
  }, [seconds]);

  
  return (
    <>
      {data && (
        <motion.div 
          className="courseFull"
          initial={{marginTop:"100%"}}
          animate={{marginTop:0}}
          transition={{duration: 0.7}}
          >
          <div className="coursePage">
            <img className="banner-img" src={data.course_banner_path} alt="" />
            <div
              className="c-head"
              dangerouslySetInnerHTML={{ __html: data.desc1 }}
            ></div>
            <div
              className="c-subhead"
              dangerouslySetInnerHTML={{ __html: data.desc2 }}
            ></div>
              {seconds === 0
                  ? <p>You are being redirected to the page in 00 seconds.</p>
                  : <p>You are being redirected to the page in 0{seconds} seconds.</p> 
              }
            </div>
          <div className="btn-explore-parent">
            <button
              className="btn-explore"
              onClick={() => window.location.replace(data.course_page_url)}
            >
              Explore This Program
            </button>
            <div className="btn-ex-desc">
              press <strong>Enter ↵</strong>
            </div>
          </div>
        </motion.div>
      )}
    </>
  );
};

export default CoursePage;
