import react, { createContext } from "react";

export const AnsContext = createContext();

const AnsState = (props) => {
    const ans ={};
    const update = (res,i) => {
        ans[i] = res;
    }
    return (
        <AnsContext.Provider value={{ans:ans, update:update}}>
            {props.children}
        </AnsContext.Provider>
    )
}

export default AnsState;