export const formDataStart = [
  {
    formId: "111",
    Questions: {
      1: {
        question: "Before we begin, what is your first name?",
        category: "text",
        type: "first name",
        placeholder: "Type your answer here...",
        isLastStep:0
      },
    },
  },
];
export const formDataEnd = [ 
  {
    formId: "111",
    Questions: {
      1: {
        question: "What is your email?",
        category: "text",
        type: "email",
        questionDescription:
          "This is where we will send you your learning recommendations based on your answers.",
        placeholder: "name@example.com",
        isLastStep:0
      },
      2:{
        question: "Please enter your contact number",
        category: "text",
        type: "number",
        questionDescription:
          "This will help our academic managers to guide you about the program",
        placeholder: "9955667788",
        isLastStep:1
      }
    },
  },
];
